<template>
	<div class="login-container">
		<el-row>
			<el-col :xs="{span:0}"
					:sm="{span:13}"
					:lg="{span:15}"
					class="login-banner">
			</el-col>
			<el-col :xs="{span:24}"
					:sm="{span:11}"
					:lg="{span:9}"
					class="login-form">
				<div class="login-header">
					<img :src="logo" />
					<h1>{{title}}</h1>
				</div>
				<el-form :rules="rules"
						 :model="account"
						 ref="frmLogin">
					<el-form-item prop="userCode">
						<el-input v-model="account.userCode"
								  clearable
								  placeholder="请输入用户名"
								  prefix-icon="el-icon-s-custom"
								  @keyup.enter.native.prevent="loginSubmit"></el-input>
					</el-form-item>
					<el-form-item prop="password">
						<el-input v-model="account.password"
								  type="password"
								  clearable
								  placeholder="请输入密码"
								  prefix-icon="el-icon-key"
								  @keyup.enter.native.prevent="loginSubmit"></el-input>
					</el-form-item>
					<el-form-item prop="delivery"
								  class="switch-item">
						<el-switch v-model="account.saveCode"
								   active-text="记住用户名"></el-switch>
					</el-form-item>
					<el-form-item>
						<el-button class="login-submit"
								   :loading="isLogin"
								   @click="loginSubmit">登　录</el-button>
					</el-form-item>
				</el-form>
				<div class="login-footer">
					<div>{{copyright}}</div>
					<div><a href="https://beian.miit.gov.cn"
						   target="_blank">皖ICP备18007722号-1</a></div>
				</div>
			</el-col>
		</el-row>
	</div>
</template>

<script>
	import defaultSettings from '@/settings'
	import { getCopyright } from '@/utils/utils.pager.js'
	import { getUserCode, getSaveCode } from '@/utils/auth'

	export default {
		data () {
			return {
				title: defaultSettings.title,
				logo: defaultSettings.logoPath,
				copyright: getCopyright(),
				isLogin: false,
				account: {
					userCode: getSaveCode() !== "false" ? getUserCode() : '',
					password: '',
					saveCode: getSaveCode() !== "false"
				},
				rules: {
					userCode: [
						{ required: true, message: '请输入登录名', trigger: 'blur' },
						{ min: 5, max: 20, message: '登录名长度在 5 到 20 个字符', trigger: 'blur' }
					],
					password: [
						{ required: true, message: '请输入密码', trigger: 'blur' },
						{ min: 6, max: 30, message: '密码长度需在 6 到 30 个字符', trigger: 'blur' }
					]
				}
			}
		},
		methods: {
			loginSubmit () {
				this.$refs['frmLogin'].validate((valid) => {
					if (valid) {
						this.isLogin = true;
						this.$store.dispatch('account/login', this.account)
							.then(() => {
								this.$router.push({ path: this.redirect || '/', query: this.otherQuery })
								this.isLogin = false
							})
							.catch(() => {
								this.isLogin = false
							})
					} else {
						return false;
					}
				});
			}
		}
	}
</script>

<style lang="scss" scoped>
	.login-container {
		padding: 0;
		margin: 0;
		height: 100%;
		width: 100%;
		min-width: 360px;
		min-height: 550px;
		> .el-row {
			height: 100%;
		}
		.login-banner {
			height: 100%;
			background: #fff url(../../assets/images/login-banner.png) right center
				no-repeat;
			background-size: cover;
			-webkit-background-size: cover;
			-o-background-size: cover;
		}
		.login-form {
			position: relative;
			height: 100%;
			background: #fff;
			> .el-form {
				margin: 30px auto 50px auto;
				width: 80%;
				.el-form-item {
					margin-bottom: 36px;
					&.label-item {
						margin-bottom: 10px;
						> .el-form-item__content {
							font-size: 24px;
							line-height: 48px;
						}
					}
					&.switch-item {
						margin-top: -32px;
						margin-bottom: 10px;
						float: right;
					}
				}
				.el-input__inner {
					height: 48px;
					line-height: 48px;
					font-size: 18px;
				}
				.el-form-item__error {
					padding-top: 6px;
					font-size: 13px;
				}
			}
			.login-submit {
				font-family: PingFangSC;
				margin-top: 10px;
				width: 100%;
				height: 45px;
				line-height: 45px;
				padding: 0 10px;
				vertical-align: middle;
				text-align: center;
				background-image: linear-gradient(
					-237deg,
					#3171ff 0%,
					#429eec 100%
				);
				color: #fff;
				border: 0;
				font-size: 18px;
				cursor: pointer;
				border-radius: 4px;
				&:hover {
					background-image: linear-gradient(
						-237deg,
						#3171ff 0%,
						#429eec 50%
					);
				}
			}
		}
		.login-header {
			text-align: center;
			padding: 50px 20px 20px 20px;
			width: 80%;
			margin: 0 auto;
			img {
				width: 96px;
				height: 96px;
				clear: both;
			}
			h1 {
				margin: 10px auto;
				font-size: 30px;
				line-height: 36px;
				color: #000044;
			}
		}
		.login-footer {
			position: absolute;
			box-sizing: border-box;
			bottom: 0;
			width: 100%;
			padding: 10px;
			line-height: 24px;
			font-size: 12px;
			color: #888888;
			text-align: center;
		}
	}
</style>
